import { Typography } from "@mui/material";
import clsx from "clsx";
import { toast } from "react-toastify";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import Button, { BUTTON_VARIANTS } from "../../components/Button";
import CodeSnippet, {
  THEME as CodeSnippetTheme,
} from "../../components/CodeSnippet";
import CustomCheckBox, { THEME } from "../../components/CustomCheckBox";
import RocketIcon from "../../icons/RocketIcon";
import InstallStep from "./InstallStep";
import { containerClassName, getCommand, TabType } from "./utils";

const HAS_COPY_BUTTON = false;
const codeSnippetClassName = "w-[43rem]";

const showSuccessToast = () => {
  toast.success("Command copied to clipboard!", {
    position: "top-center",
  });
};
interface Props {
  token: string;
  customerPrefix: string;
  selectedTab: TabType;
}

const GuideSection = ({ token, customerPrefix, selectedTab }: Props) => {
  const [hasOpenShift, setHasOpenShift] = useQueryParam(
    "useOpenshift",
    withDefault(StringParam, "false"),
  );

  return (
    <div className="relative">
      <video
        src="https://scaleops.com/wp-content/uploads/2024/05/Scaleops-Dashboard.mp4"
        style={{
          width: "35%",
          position: "absolute",
          zIndex: 1,
          top: "50%",
          right: "0%",
          transform: "translate(-5%, -50%)",
          border: "5px solid #CBD5E0",
          borderRadius: "20px",
        }}
        className="shadow-2xl"
        muted
        autoPlay
        loop
      />
      <div
        className={clsx(
          containerClassName,
          "bg-white border border-1 border-black relative flex flex-col gap-5",
        )}
      >
        {selectedTab === TabType.INSTALL && (
          <InstallStep
            stepNumber={1}
            title={
              <>
                Run the <b>installation command</b>
              </>
            }
            content={
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <CustomCheckBox
                    value={hasOpenShift === "true"}
                    onClick={(checked: boolean | undefined) => {
                      setHasOpenShift(checked ? "true" : "false");
                    }}
                    theme={THEME.PURPLE}
                  />
                  <Typography variant="body2">OpenShift</Typography>
                </div>
                <CodeSnippet
                  theme={CodeSnippetTheme.light}
                  codeSnippet={getCommand({
                    commandType: "install",
                    customerPrefix,
                    hasOpenShift: hasOpenShift === "true",
                    showToken: false,
                    token,
                  })}
                  codeSnippetToCopy={getCommand({
                    commandType: "install",
                    customerPrefix,
                    hasOpenShift: hasOpenShift === "true",
                    showToken: true,
                    token,
                  })}
                  className={codeSnippetClassName}
                />
                {HAS_COPY_BUTTON && (
                  <Button
                    label="COPY"
                    variant={BUTTON_VARIANTS.mediumDarkPurple}
                    onClick={() => {
                      let codeToCopy = getCommand({
                        commandType: "install",
                        customerPrefix,
                        hasOpenShift: hasOpenShift === "true",
                        showToken: true,
                        token,
                      });
                      codeToCopy = codeToCopy.trim();
                      navigator.clipboard.writeText(codeToCopy);
                      showSuccessToast();
                    }}
                    fontSize={14}
                  />
                )}
              </div>
            }
          />
        )}
        {selectedTab === TabType.UPGRADE && (
          <InstallStep
            stepNumber={1}
            title={
              <>
                Run the <b>upgrade command</b>
              </>
            }
            content={
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <CustomCheckBox
                    value={hasOpenShift === "true"}
                    onClick={(checked: boolean | undefined) => {
                      setHasOpenShift(checked ? "true" : "false");
                    }}
                    theme={THEME.PURPLE}
                  />
                  <Typography variant="body2">OpenShift</Typography>
                </div>
                <CodeSnippet
                  theme={CodeSnippetTheme.light}
                  codeSnippet={getCommand({
                    commandType: "upgrade",
                    hasOpenShift: hasOpenShift === "true",
                    token,
                  })}
                  codeSnippetToCopy={getCommand({
                    commandType: "upgrade",
                    hasOpenShift: hasOpenShift === "true",
                    token,
                    showToken: true,
                  })}
                  className={codeSnippetClassName}
                />
                {HAS_COPY_BUTTON && (
                  <Button
                    label="COPY"
                    variant={BUTTON_VARIANTS.mediumDarkPurple}
                    onClick={() => {
                      let codeToCopy = getCommand({
                        commandType: "upgrade",
                        token,
                        hasOpenShift: hasOpenShift === "true",
                        showToken: true,
                      });
                      codeToCopy = codeToCopy.trim();
                      navigator.clipboard.writeText(codeToCopy);
                      showSuccessToast();
                    }}
                    fontSize={14}
                  />
                )}
              </div>
            }
          />
        )}
        <InstallStep
          stepNumber={2}
          title="Enable Port-Forward"
          content={
            <div className="flex flex-col gap-2">
              <CodeSnippet
                theme={CodeSnippetTheme.light}
                codeSnippet={getCommand({
                  commandType: "portForward",
                  hasOpenShift: hasOpenShift === "true",
                  showToken: false,
                  token,
                })}
                className={codeSnippetClassName}
              />
            </div>
          }
        />
        <InstallStep
          stepNumber={3}
          title={
            <b>
              Get Started!{" "}
              <span className="text-primary-purpleBlue">
                Optimize your cluster
              </span>
            </b>
          }
          content={
            <div className="flex items-center gap-2">
              <RocketIcon />
              <Typography variant="body1" fontWeight={700}>
                <span>
                  Browse to{" "}
                  <a
                    className="text-primary-purpleBlue underline hover:text-main-blue"
                    href="http://localhost:8080"
                    target="_blank"
                    rel="noreferrer"
                  >
                    http://localhost:8080
                  </a>
                </span>
              </Typography>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default GuideSection;
