import { Client, GithubToken, MetricsVisability } from "../models";
import React, { useEffect, useState } from "react";
import { DataStore } from "aws-amplify";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ModalProps,
} from "@mui/material";
import { Button, SwitchField, TextField } from "@aws-amplify/ui-react";

interface ClientCreateProps {
  open: boolean;
  onClose?: ModalProps["onClose"];
}
interface ClientForToken {
  name: string;
  url: string;
  githubToken: string;
  isScaleOpsDev: boolean;
  metricsVerbosity: MetricsVisability;
}
export default function ClientCreate(props: ClientCreateProps) {
  const [clientName, setClientName] = useState<string>("");
  const [isScaleOpsDev, setIsScaleOpsDev] = useState<boolean>(false);
  const [encKey, setEncKey] = useState<CryptoKey | null>(null);

  useEffect(() => {
    crypto.subtle
      .generateKey(
        {
          name: "HMAC",
          hash: { name: "SHA-256" },
        },
        true,
        ["sign", "verify"],
      )
      .then((key) => {
        setEncKey(key);
        console.log("encryption key generated");
      });
  }, []);

  const makeid = (length: number): string => {
    let result = "";
    const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const genUrl = (): string => {
    return clientName.toLowerCase().split(" ").join("-") + "/" + makeid(5);
  };

  const buf2hex = (buffer: ArrayBuffer): string => {
    return Array.from(new Uint8Array(buffer))
      .map((x) => x.toString(16).padStart(2, "0"))
      .join("")
      .slice(0, 33);
  };

  const genScaleOpsToken = async (client: ClientForToken): Promise<string> => {
    if (!encKey) {
      throw new Error("encryption key not set");
    }
    const enc = new TextEncoder();
    const now = new Date();
    const encoded = enc.encode(client.name + now.toISOString() + client.url);
    try {
      const signature = await crypto.subtle.sign("HMAC", encKey, encoded);
      return "soc_" + buf2hex(signature);
    } catch (reason) {
      console.error(reason);
      throw reason;
    }
  };

  const save = () => {
    // Once we will move away from Github Tokens all together, we will need to remove this query
    DataStore.query(
      GithubToken,
      (g) => g.and((gp) => [gp.inUse.eq(false), gp.deleted.eq(false)]),
      {
        page: 0,
        limit: 1,
      },
    ).then((tokenResult) => {
      if (tokenResult.length !== 1) {
        throw new Error("failed to locate an available github token");
      }
      const githubToken = tokenResult[0];
      const clientNameTrimmed = clientName.trim();
      const url = genUrl();
      const client: ClientForToken = {
        name: clientNameTrimmed,
        githubToken: githubToken.token,
        url: url,
        metricsVerbosity: MetricsVisability.KEEP,
        isScaleOpsDev: isScaleOpsDev,
      };
      genScaleOpsToken(client)
        .then((scaleOpsToken) => {
          DataStore.save(
            new Client({
              ...client,
              useScaleOpsToken: false,
              scaleOpsToken: scaleOpsToken,
            }),
          )
            .then((saveResult) => {
              console.log("client created successfully ", saveResult);
              DataStore.save(
                GithubToken.copyOf(githubToken, (draft) => {
                  draft.inUse = true;
                }),
              )
                .then((saveTokenResult) => {
                  console.log("token updated successfully ", saveTokenResult);
                  if (props.onClose) {
                    props.onClose({}, "escapeKeyDown");
                  }
                })
                .catch((reason) => {
                  console.error(reason);
                });
            })
            .catch((reason) => {
              console.error(reason);
            });
        })
        .catch((reason) => {
          console.error(reason);
        });
    });
  };

  return (
    <Dialog onClose={props.onClose} open={props.open}>
      <DialogTitle>Create a new client</DialogTitle>
      <DialogContent>
        <TextField
          label={"Client Name"}
          onChange={(event) => {
            setClientName(event.target.value);
          }}
        />
        <SwitchField
          display="none" // If and when we wanted to expose this functionality, we would need to change this to "block"
          label={"ScaleOps Dev"}
          onChange={(event) => setIsScaleOpsDev(event.target.checked)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => save()}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}
