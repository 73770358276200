import { Client } from "../models";
import React from "react";
import { Dialog, DialogContent, DialogTitle, ModalProps } from "@mui/material";
import { ClientUpdateForm } from "../ui-components";

interface ClientEditProps {
  onClose?: ModalProps["onClose"];
  client?: Client;
}
export default function ClientEdit(props: ClientEditProps) {
  return (
    <Dialog onClose={props.onClose} open={props.client !== undefined}>
      <DialogTitle>{"Edit " + props.client?.name}</DialogTitle>
      <DialogContent>
        <ClientUpdateForm
          overrides={{
            githubToken: {
              label: "GitHub Token",
              isDisabled: true,
            },
            scaleOpsToken: {
              label: "ScaleOps Token",
              isDisabled: true,
            },
            isScaleOpsDev: {
              label: "Is ScaleOps Development",
            },
            useScaleOpsToken: {
              label: "Use ScaleOps Token",
            },
            url: {
              label: "URL",
              isDisabled: true,
            },
          }}
          onError={(fields, errorMessage) => {
            console.error(errorMessage);
            console.log(fields);
          }}
          onSuccess={() => {
            if (props.onClose !== undefined) {
              props.onClose({}, "escapeKeyDown");
            }
          }}
          client={props.client}
        />
      </DialogContent>
    </Dialog>
  );
}
