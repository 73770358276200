/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SelectField,
  SwitchField,
  TextField,
} from "@aws-amplify/ui-react";
import { Client } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify";
export default function ClientUpdateForm(props) {
  const {
    id: idProp,
    client: clientModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    name: "",
    githubToken: "",
    scaleOpsToken: "",
    isScaleOpsDev: false,
    useScaleOpsToken: false,
    url: "",
    metricsVerbosity: "",
    isDeleted: false,
  };
  const [name, setName] = React.useState(initialValues.name);
  const [githubToken, setGithubToken] = React.useState(
    initialValues.githubToken,
  );
  const [scaleOpsToken, setScaleOpsToken] = React.useState(
    initialValues.scaleOpsToken,
  );
  const [isScaleOpsDev, setIsScaleOpsDev] = React.useState(
    initialValues.isScaleOpsDev,
  );
  const [useScaleOpsToken, setUseScaleOpsToken] = React.useState(
    initialValues.useScaleOpsToken,
  );
  const [url, setUrl] = React.useState(initialValues.url);
  const [metricsVerbosity, setMetricsVerbosity] = React.useState(
    initialValues.metricsVerbosity,
  );
  const [isDeleted, setIsDeleted] = React.useState(initialValues.isDeleted);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = clientRecord
      ? { ...initialValues, ...clientRecord }
      : initialValues;
    setName(cleanValues.name);
    setGithubToken(cleanValues.githubToken);
    setScaleOpsToken(cleanValues.scaleOpsToken);
    setIsScaleOpsDev(cleanValues.isScaleOpsDev);
    setUseScaleOpsToken(cleanValues.useScaleOpsToken);
    setUrl(cleanValues.url);
    setMetricsVerbosity(cleanValues.metricsVerbosity);
    setIsDeleted(cleanValues.isDeleted);
    setErrors({});
  };
  const [clientRecord, setClientRecord] = React.useState(clientModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(Client, idProp)
        : clientModelProp;
      setClientRecord(record);
    };
    queryData();
  }, [idProp, clientModelProp]);
  React.useEffect(resetStateValues, [clientRecord]);
  const validations = {
    name: [{ type: "Required" }],
    githubToken: [{ type: "Required" }],
    scaleOpsToken: [],
    isScaleOpsDev: [],
    useScaleOpsToken: [],
    url: [{ type: "Required" }],
    metricsVerbosity: [],
    isDeleted: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue,
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          name,
          githubToken,
          scaleOpsToken,
          isScaleOpsDev,
          useScaleOpsToken,
          url,
          metricsVerbosity,
          isDeleted,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item),
                ),
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName]),
            );
            return promises;
          }, []),
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            Client.copyOf(clientRecord, (updated) => {
              Object.assign(updated, modelFields);
            }),
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "ClientUpdateForm")}
      {...rest}
    >
      <TextField
        label="Name"
        isRequired={true}
        isReadOnly={false}
        value={name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name: value,
              githubToken,
              scaleOpsToken,
              isScaleOpsDev,
              useScaleOpsToken,
              url,
              metricsVerbosity,
              isDeleted,
            };
            const result = onChange(modelFields);
            value = result?.name ?? value;
          }
          if (errors.name?.hasError) {
            runValidationTasks("name", value);
          }
          setName(value);
        }}
        onBlur={() => runValidationTasks("name", name)}
        errorMessage={errors.name?.errorMessage}
        hasError={errors.name?.hasError}
        {...getOverrideProps(overrides, "name")}
      ></TextField>
      <TextField
        label="Github token"
        isRequired={true}
        isReadOnly={false}
        value={githubToken}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              githubToken: value,
              scaleOpsToken,
              isScaleOpsDev,
              useScaleOpsToken,
              url,
              metricsVerbosity,
              isDeleted,
            };
            const result = onChange(modelFields);
            value = result?.githubToken ?? value;
          }
          if (errors.githubToken?.hasError) {
            runValidationTasks("githubToken", value);
          }
          setGithubToken(value);
        }}
        onBlur={() => runValidationTasks("githubToken", githubToken)}
        errorMessage={errors.githubToken?.errorMessage}
        hasError={errors.githubToken?.hasError}
        {...getOverrideProps(overrides, "githubToken")}
      ></TextField>
      <TextField
        label="Scale ops token"
        isRequired={false}
        isReadOnly={false}
        value={scaleOpsToken}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              githubToken,
              scaleOpsToken: value,
              isScaleOpsDev,
              useScaleOpsToken,
              url,
              metricsVerbosity,
              isDeleted,
            };
            const result = onChange(modelFields);
            value = result?.scaleOpsToken ?? value;
          }
          if (errors.scaleOpsToken?.hasError) {
            runValidationTasks("scaleOpsToken", value);
          }
          setScaleOpsToken(value);
        }}
        onBlur={() => runValidationTasks("scaleOpsToken", scaleOpsToken)}
        errorMessage={errors.scaleOpsToken?.errorMessage}
        hasError={errors.scaleOpsToken?.hasError}
        {...getOverrideProps(overrides, "scaleOpsToken")}
      ></TextField>
      <SwitchField
        label="Is scale ops dev"
        defaultChecked={false}
        isDisabled={false}
        isChecked={isScaleOpsDev}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              name,
              githubToken,
              scaleOpsToken,
              isScaleOpsDev: value,
              useScaleOpsToken,
              url,
              metricsVerbosity,
              isDeleted,
            };
            const result = onChange(modelFields);
            value = result?.isScaleOpsDev ?? value;
          }
          if (errors.isScaleOpsDev?.hasError) {
            runValidationTasks("isScaleOpsDev", value);
          }
          setIsScaleOpsDev(value);
        }}
        onBlur={() => runValidationTasks("isScaleOpsDev", isScaleOpsDev)}
        errorMessage={errors.isScaleOpsDev?.errorMessage}
        hasError={errors.isScaleOpsDev?.hasError}
        {...getOverrideProps(overrides, "isScaleOpsDev")}
      ></SwitchField>
      <SwitchField
        label="Use scale ops token"
        defaultChecked={false}
        isDisabled={false}
        isChecked={useScaleOpsToken}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              name,
              githubToken,
              scaleOpsToken,
              isScaleOpsDev,
              useScaleOpsToken: value,
              url,
              metricsVerbosity,
              isDeleted,
            };
            const result = onChange(modelFields);
            value = result?.useScaleOpsToken ?? value;
          }
          if (errors.useScaleOpsToken?.hasError) {
            runValidationTasks("useScaleOpsToken", value);
          }
          setUseScaleOpsToken(value);
        }}
        onBlur={() => runValidationTasks("useScaleOpsToken", useScaleOpsToken)}
        errorMessage={errors.useScaleOpsToken?.errorMessage}
        hasError={errors.useScaleOpsToken?.hasError}
        {...getOverrideProps(overrides, "useScaleOpsToken")}
      ></SwitchField>
      <TextField
        label="Url"
        isRequired={true}
        isReadOnly={false}
        value={url}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              githubToken,
              scaleOpsToken,
              isScaleOpsDev,
              useScaleOpsToken,
              url: value,
              metricsVerbosity,
              isDeleted,
            };
            const result = onChange(modelFields);
            value = result?.url ?? value;
          }
          if (errors.url?.hasError) {
            runValidationTasks("url", value);
          }
          setUrl(value);
        }}
        onBlur={() => runValidationTasks("url", url)}
        errorMessage={errors.url?.errorMessage}
        hasError={errors.url?.hasError}
        {...getOverrideProps(overrides, "url")}
      ></TextField>
      <SelectField
        label="Metrics verbosity"
        placeholder="Please select an option"
        isDisabled={false}
        value={metricsVerbosity}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              githubToken,
              scaleOpsToken,
              isScaleOpsDev,
              useScaleOpsToken,
              url,
              metricsVerbosity: value,
              isDeleted,
            };
            const result = onChange(modelFields);
            value = result?.metricsVerbosity ?? value;
          }
          if (errors.metricsVerbosity?.hasError) {
            runValidationTasks("metricsVerbosity", value);
          }
          setMetricsVerbosity(value);
        }}
        onBlur={() => runValidationTasks("metricsVerbosity", metricsVerbosity)}
        errorMessage={errors.metricsVerbosity?.errorMessage}
        hasError={errors.metricsVerbosity?.hasError}
        {...getOverrideProps(overrides, "metricsVerbosity")}
      >
        <option
          children="Keep"
          value="KEEP"
          {...getOverrideProps(overrides, "metricsVerbosityoption0")}
        ></option>
        <option
          children="None"
          value="NONE"
          {...getOverrideProps(overrides, "metricsVerbosityoption1")}
        ></option>
        <option
          children="Minimal"
          value="MINIMAL"
          {...getOverrideProps(overrides, "metricsVerbosityoption2")}
        ></option>
        <option
          children="Poc"
          value="POC"
          {...getOverrideProps(overrides, "metricsVerbosityoption3")}
        ></option>
        <option
          children="All"
          value="ALL"
          {...getOverrideProps(overrides, "metricsVerbosityoption4")}
        ></option>
      </SelectField>
      <SwitchField
        label="Is deleted"
        defaultChecked={false}
        isDisabled={false}
        isChecked={isDeleted}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              name,
              githubToken,
              scaleOpsToken,
              isScaleOpsDev,
              useScaleOpsToken,
              url,
              metricsVerbosity,
              isDeleted: value,
            };
            const result = onChange(modelFields);
            value = result?.isDeleted ?? value;
          }
          if (errors.isDeleted?.hasError) {
            runValidationTasks("isDeleted", value);
          }
          setIsDeleted(value);
        }}
        onBlur={() => runValidationTasks("isDeleted", isDeleted)}
        errorMessage={errors.isDeleted?.errorMessage}
        hasError={errors.isDeleted?.hasError}
        {...getOverrideProps(overrides, "isDeleted")}
      ></SwitchField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || clientModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || clientModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
