/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getClientByHashName = /* GraphQL */ `
  query GetClientByHashName($name: String, $hash: String, $token: String) {
    getClientByHashName(name: $name, hash: $hash, token: $token) {
      id
      name
      githubToken
      scaleOpsToken
      isScaleOpsDev
      useScaleOpsToken
      url
      metricsVerbosity
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const getGithubToken = /* GraphQL */ `
  query GetGithubToken($id: ID!) {
    getGithubToken(id: $id) {
      id
      token
      inUse
      deleted
      name
      tokenStatus
      tokenPath
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listGithubTokens = /* GraphQL */ `
  query ListGithubTokens(
    $filter: ModelGithubTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGithubTokens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        token
        inUse
        deleted
        name
        tokenStatus
        tokenPath
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncGithubTokens = /* GraphQL */ `
  query SyncGithubTokens(
    $filter: ModelGithubTokenFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGithubTokens(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        token
        inUse
        deleted
        name
        tokenStatus
        tokenPath
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      name
      githubToken
      scaleOpsToken
      isScaleOpsDev
      useScaleOpsToken
      url
      metricsVerbosity
      isDeleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        githubToken
        scaleOpsToken
        isScaleOpsDev
        useScaleOpsToken
        url
        metricsVerbosity
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClients = /* GraphQL */ `
  query SyncClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        githubToken
        scaleOpsToken
        isScaleOpsDev
        useScaleOpsToken
        url
        metricsVerbosity
        isDeleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
