import { Typography } from "@mui/material";

const ICON_SIZE = 45;

interface Props {
  stepNumber: number;
  title: React.ReactNode;
  content: React.ReactNode;
}

const InstallStep = ({ stepNumber, title, content }: Props) => (
  <div className="flex gap-[1.875rem] w-full">
    <Typography
      variant="h5"
      className="rounded-full bg-guideline-darkPurple flex justify-center items-center text-white"
      style={{
        width: ICON_SIZE,
        height: ICON_SIZE,
      }}
    >
      {stepNumber}
    </Typography>

    <div className="flex flex-col gap-2">
      <Typography variant="h5">{title}</Typography>
      <div>{content}</div>
    </div>
  </div>
);

export default InstallStep;
