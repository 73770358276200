import { confirmAlert } from "react-confirm-alert";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@aws-amplify/ui-react";

interface DeleteButtonProps {
  isDisabled: boolean;
  deleteDetails: string;
  deleteAction: any;
}

const DeleteButton = ({
  isDisabled,
  deleteDetails,
  deleteAction,
}: DeleteButtonProps) => {
  return (
    <>
      <Button
        disabled={isDisabled}
        variation="menu"
        size="small"
        color="grey"
        onClick={() => {
          confirmAlert({
            title: "Are You Sure?",
            message: deleteDetails,
            buttons: [
              {
                label: "Yes",
                onClick: deleteAction,
              },
              {
                label: "No",
                onClick: () => {},
              },
            ],
          });
        }}
        ariaLabel="Delete Client"
      >
        <DeleteIcon />
      </Button>
    </>
  );
};
export default DeleteButton;
