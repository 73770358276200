// @ts-check
import { initSchema } from "@aws-amplify/datastore";
import { schema } from "./schema";

const MetricsVisability = {
  KEEP: "KEEP",
  NONE: "NONE",
  MINIMAL: "MINIMAL",
  POC: "POC",
  ALL: "ALL",
};

const TokenStatus = {
  ACTIVE: "ACTIVE",
  TO_BE_DELETED: "TO_BE_DELETED",
  DELETED: "DELETED",
};

const { GithubToken, Client } = initSchema(schema);

export { GithubToken, Client, MetricsVisability, TokenStatus };
