export const schema = {
  models: {
    GithubToken: {
      name: "GithubToken",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        token: {
          name: "token",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        inUse: {
          name: "inUse",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        deleted: {
          name: "deleted",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        tokenStatus: {
          name: "tokenStatus",
          isArray: false,
          type: {
            enum: "TokenStatus",
          },
          isRequired: false,
          attributes: [],
        },
        tokenPath: {
          name: "tokenPath",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "GithubTokens",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Client: {
      name: "Client",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        githubToken: {
          name: "githubToken",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        scaleOpsToken: {
          name: "scaleOpsToken",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        isScaleOpsDev: {
          name: "isScaleOpsDev",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        useScaleOpsToken: {
          name: "useScaleOpsToken",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        url: {
          name: "url",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        metricsVerbosity: {
          name: "metricsVerbosity",
          isArray: false,
          type: {
            enum: "MetricsVisability",
          },
          isRequired: false,
          attributes: [],
        },
        isDeleted: {
          name: "isDeleted",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Clients",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["get"],
              },
            ],
          },
        },
      ],
    },
  },
  enums: {
    MetricsVisability: {
      name: "MetricsVisability",
      values: ["KEEP", "NONE", "MINIMAL", "POC", "ALL"],
    },
    TokenStatus: {
      name: "TokenStatus",
      values: ["ACTIVE", "TO_BE_DELETED", "DELETED"],
    },
  },
  nonModels: {},
  codegenVersion: "3.4.4",
  version: "13ce2babc45da3605ad6846659a6473d",
};
